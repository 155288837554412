import { Box, Stack, Typography, Button } from "@mui/material";
import { ArtistProps } from "@types";
import { Link } from "react-router-dom";
import Avatar from "components/Avatar";
import Image from "components/Image";
import { primaryButtonStyles } from "utils/cssStyles";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

const subscribeButtonStyle = {
  width: "auto",
  height: "45px",
  padding: "10px 20px",
  borderRadius: "20px",
};

const ArtistCard = ({ bannerImg, avatarImg, username, _id, fans }: ArtistProps) => {

  return (
    <Box sx={{ bgcolor: "rgba(237, 237, 237, 1)", cursor: "pointer", borderRadius: "20px" }}>
      <Stack direction='row'>
        <Image
          src={bannerImg}
          alt='artist'
          sx={{ width: "241px", height: "195px", borderRadius: "20px", opacity: "0px" }}
        />
        <Stack sx={{ paddingTop: 8, paddingLeft: 2, paddingRight: 2, alignItems: "center" }}>
          
          <Box sx={{ textAlign: "center" }}>
            <Typography variant='h4' sx={{ color: "common.black", textTransform: "uppercase" }}>
              {fans}
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ color: "common.black", textTransform: "uppercase", whiteSpace: "nowrap" }}
            >
              fan base
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Box
        sx={{
          padding: 1,
          bgcolor: "common.white",
          borderRadius: 1.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction='row' spacing={1}>
          <Avatar src={avatarImg} alt='artist avatar' />
          <Box>
            <Typography variant='subtitle1' sx={{ color: "common.black" }}>
              {username}
            </Typography>
            <Typography variant='subtitle2' sx={{ color: "grey.800" }}>
              @{username}
            </Typography>
          </Box>
        </Stack>
        <Link to={`/book/${username}`} state={{ id: _id }} replace>
          <Button variant='contained' sx={{ ...primaryButtonStyles, ...subscribeButtonStyle }}>
            Join Fandom
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default ArtistCard;
