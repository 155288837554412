import { Grid, Typography, Link } from "@mui/material";
import { ArtistCommunityCard } from "components/cards";
import { styled } from "@mui/material/styles";
import { ArtistProps } from "@types";

const ContentStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2, 10),
}));

type TrendingArtistsProps = {
  trending: ArtistProps[];
};

export default function TrendingArtists({ trending }: TrendingArtistsProps) {
  return (
    <ContentStyle>
      <Typography
        variant="h3"
        sx={{
          color: "common.black",
          // width: "16ch",
          textTransform: "uppercase",
          fontWeight: "bold",
          // fontFamily: "Dela Gothic One, cursive",
        }}
      >
        Trending Communities
      </Typography>
      <Grid container spacing={2} sx={{ my: 5, justifyContent: "center", width: "100%" }}>
        {trending.slice(0, 6).map((artist) => (
          <Grid item key={artist._id}>
            <Link href={`/book/${artist._id}`}>
              <ArtistCommunityCard
                _id={artist._id}
                avatarImg={artist.avatarImg}
                username={artist.username}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </ContentStyle>
  );
}
