// @mui
import { styled } from "@mui/material/styles";
// sections
import { Fans, SearchForm, TrendingArtists } from "../sections/search";
import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import { ArtistProps, UserProps } from "@types";
import ArtistList from "sections/search/ArtistList";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
}));

// ----------------------------------------------------------------------

export default function Explore() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [trending, setTrending] = useState<ArtistProps[]>([]);
  const [topfans, setTopfans] = useState<UserProps[]>([]);
  const [filteredArtists, setFilteredArtists] = useState<ArtistProps[]>([]);

  const fans = useAppSelector((state) => state.fans.fans);
  const artists = useAppSelector((state) => state.artist.artists);
  const isAuthenticated = useAppSelector((state) => state.user.authenticated);

  const topFans: UserProps[] = [...fans].sort((a: any, b: any) => {
    if (b.points === undefined) return -1;
    if (a.points === undefined) return -1;
    return b.points - a.points;
  });

  // const trending: ArtistProps[] = [...artists]?.sort((a, b) => b.subscribedUsers.length - a.subscribedUsers.length);
  useEffect(() => { 
    const trendingArtists = async() => {
      try{
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/artist/trending`);
        setTrending(res.data);
      }catch(err) {
        console.log(err);
      }
    };
    trendingArtists();
  }, [])
  
  useEffect(() => {
    const getTopFans = async() => {
      try{
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/fans/top`);
        setTopfans(res.data);
      }catch(err) {
        console.log(err)
      }
    }
    getTopFans();
  }, [])

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: `/` }, replace: true });
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (search) {
      setFilteredArtists(
        artists.filter((item) =>
          item.username?.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredArtists([]);
    }
  }, [search, artists]);

  return (
    <RootStyle>
      <SearchForm onSearch={setSearch} />
      {search && <ArtistList artists={filteredArtists} />}
      <TrendingArtists trending={trending} />
      <Fans topFans={topfans} />
    </RootStyle>
  );
}
