import { styled } from "@mui/material/styles";
import { Box, Container, Typography, Stack } from "@mui/material";
import { ArtistCard } from "components/cards";
import { ArtistProps } from "@types";

const ContentStyle = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2, 5),
  backgroundColor: "rgba(248, 248, 248, 1)",
}));

type ArtistListProps = {
  trending: ArtistProps[];
};

export default function Trending({ trending }: ArtistListProps) {

  return (
    <ContentStyle>
      <Container>
        <Stack justifyContent="space-between">
          <Typography variant="h2" sx={{ color: "common.black", fontWeight: 700 }}>
            Trending Creators
          </Typography>
        </Stack>
        <Box
          sx={{
            display: "grid",
            mt: 5,
            gap: { xs: 5, lg: 10 },
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(3, 1fr)",
              md: "repeat(3, 1fr)",
            },
          }}
        >
          {trending.slice(0, 6).map((artist) => (
            <ArtistCard
              key={artist._id}
              _id={artist._id}
              bannerImg={artist.bannerImg}
              avatarImg={artist.avatarImg}
              username={artist.username}
              fans={artist.fans}
              subscribedUsers={artist.subscribedUsers}
              isVerified={artist.isVerified}
              videos={artist.videos}
            />
          ))}
        </Box>
      </Container>
    </ContentStyle>
  );
}
