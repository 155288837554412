import { styled } from "@mui/material/styles";
import { SearchForm, Fans } from "../sections/search";
import { useStore } from "store/store";
import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import { ArtistProps, UserProps } from "@types";
import SearchNotFound from "components/SearchNotFound";
import { Trending } from "sections/home";
import ArtistList from "sections/search/ArtistList";
import { useNavigate } from "react-router-dom";

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
}));

export default function Search() {
  const { getArtists } = useStore();
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchResults, setSearchResults] = useState<ArtistProps[]>([]);
  const [searchResultsFound, setSearchResultsFound] = useState(true);

  const navigate = useNavigate();

  const fans = useAppSelector((state) => state.fans.fans);
  const artists = useAppSelector((state) => state.artist.artists);
  const isAuthenticated = useAppSelector((state) => state.user.authenticated);

  const topFans: UserProps[] = [...fans].sort((a, b) => {
    if (b.points === undefined) return -1;
    if (a.points === undefined) return -1;
    return b.points - a.points;
  });

  const trending: ArtistProps[] = [...artists].sort(
    (a, b) => b.subscribedUsers.length - a.subscribedUsers.length
  );

  useEffect(() => {
    if(!isAuthenticated) {
      navigate("/login", {state: { from: "/search"}, replace: true })
    }
  },[isAuthenticated, navigate]);

  useEffect(() => {
    getArtists();
  }, [getArtists]);

  const handleSearch = (query: string) => {
    setSearchInProgress(true);

    const filteredArtists = artists.filter((artist) =>
      artist?.username?.toLowerCase().includes(query.toLowerCase())
    );

    setTimeout(() => {
      setSearchInProgress(false);
      setSearchResults(filteredArtists);

      setSearchResultsFound(filteredArtists.length > 0);
    }, 1000);
  };

  return (
    <RootStyle>
      <SearchForm onSearch={handleSearch} />
      {searchInProgress ? (
        <p>Loading...</p>
      ) : searchResultsFound ? (
        <ArtistList artists={searchResults} />
      ) : (
        <SearchNotFound />
      )}
      <Trending trending={trending} />
      <Fans topFans={topFans} />
    </RootStyle>
  );
}
